import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog({ onClose, open }) {


  return (
    <div>

      <Dialog
        fullScreen
        open={open}
        onClose={onClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative',background:'#e83329e9' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={onClose}
              aria-label="close"
            >
         <CloseIcon />&nbsp; &nbsp;Close
          
         
            </IconButton>
        
          </Toolbar>
        </AppBar>
       
         <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSd4dYovUYSToJmwp0IoYqof-c17mniQqk9DGydvRXgz74PB2g/viewform?embedded=true"
           width="100%" height="100%" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
      </Dialog>
    </div>
  );
}