import Carousel from 'react-bootstrap/Carousel';
import Card from 'react-bootstrap/Card';
import './slider.css'

export default function Header() {
  return (

    <Carousel className='sliderContainer' pause={false}>
      <Carousel.Item className='sliderBg1' />
      <Carousel.Item className='sliderBg2' />
      <Carousel.Item className='sliderBg3' />
      <Carousel.Item className='sliderBg4' />

    </Carousel>

  );
}

