import * as React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import { Nav } from 'react-bootstrap';
import { Container } from 'react-bootstrap';
import Logo2 from '../img/snackchip5.png'
import NavDropdown from 'react-bootstrap/NavDropdown';
import './Navbar.css';
import Languages from '../language.json'
import { LanguageContext } from '../App';
import { useContext } from 'react';
import Form from './Form';


export default function NavbarBar({ setLanguage }) {
    const languageOptions = ['English', 'Français', 'Português'];
    const language = useContext(LanguageContext);
    const [selectedLanguage, setSelectedLanguage] = React.useState("English");
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleLanguage = (index) => {
        if (index === 0) {
            setLanguage(Languages.En);
            setSelectedLanguage(languageOptions[0]);
        }
        else if (index === 1) {
            setLanguage(Languages.Fr);
            setSelectedLanguage(languageOptions[1]);
        }
        else if (index === 2) {
            setLanguage(Languages.Pr);
            setSelectedLanguage(languageOptions[2]);
        }
    };

    return (
        <div>
           <Form
                open={open}
                onClose={handleClose}
            />
            <div className='logoDiv'>
                <img src={Logo2} alt="logo" className='logoStyle2'></img>

                <NavDropdown title={selectedLanguage} className='language'>
                    {languageOptions.map((option, index) => (
                        <NavDropdown.Item onClick={() => handleLanguage(index)}> {option}</NavDropdown.Item>
                    ))}
                </NavDropdown>
               
            </div>

            <Navbar collapseOnSelect expand="lg" className='navStyle'>
                <Container>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav" className='navC'>

                        <Nav className='navC' >
                            <Nav.Link href="\#Home" className='navF'> {language.Home} </Nav.Link>
                            <Nav.Link href="\#Products" className='navL'> {language.Products} </Nav.Link>
                            <Nav.Link href="\#About" className='navL'>{language.Aboutus} </Nav.Link>
                            <Nav.Link href="\#Contact" className='navL' >{language.Contact} </Nav.Link>
                            <Nav.Link onClick={handleClickOpen}  className='navL' >{language.LeaveMsg} </Nav.Link>

                        </Nav>




                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
    )
}




