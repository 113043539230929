import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailIcon from '@mui/icons-material/Email';
import Sology from '../img/sology.png'
import './Contact.css'
import { LanguageContext } from '../App';
import Button from '@mui/material/Button';
import MessageIcon from '@mui/icons-material/Message';
import PhoneIcon from '@mui/icons-material/Phone';
import Form from './Form';

export default function ContactUs() {
    const language = React.useContext(LanguageContext);
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    return (
        <div className='contactContainer'>
            <Form
                open={open}
                onClose={handleClose}
            />
            <h1 className="break-top"></h1>
            <div className='footer' id="Contact">

                <Container fluid>
                    <h1 className='title' >{language.ContactUs}</h1>
                    <Row className='contactRow' >
                        <div style={{ margin: '10px 0px' }}> <a className='contactP' onClick={handleClickOpen} >  <MessageIcon /> &nbsp;  {language.LeaveMsg}</a></div>
                        <div><a className='contactP' href="tel:0765632104"> <PhoneIcon />&nbsp;  076 563 2104 </a></div>


                        <div style={{ margin: '10px 0px' }}> <a className='contactP' href="mailto: info@snackchip.co.za"> <EmailIcon /> &nbsp;  info@snackchip.co.za</a></div>

                        <div style={{ margin: '10px 0px' }}><a className='contactP' href="https://goo.gl/maps/qSfEnddnQzTnRBwD9">
                            <LocationOnIcon />&nbsp;11 6th Rd, Montague Gardens, Cape Town</a></div>





                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3313.1593481174555!2d18.5170521!3d-33.859784!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1dcc5eb5d980752f%3A0x543d838b538bc1c0!2s11%206th%20Rd%2C%20Montague%20Gardens%2C%20Cape%20Town%2C%207441!5e0!3m2!1sen!2sza!4v1672081110935!5m2!1sen!2sza"
                            style={{ width: "100%", height: "300px", borderRadius: "30px" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" title='11 6th Rd, Montague Gardens, Cape Town'></iframe>



                    </Row>

                </Container>

            </div>
            <p className='sology'>  Copyright ©
                <script>document.write(new Date().getFullYear());</script>
                2022 All rights reserved | Designed and Delivered By&nbsp;
                <a title="Sology Group" href="http://sology.net" target="_blank" style={{ color: "white" }}>
                    <img src={Sology} style={{ height: "15px", paddingLeft: "3px" }}></img></a>
            </p>
        </div>
    )
}

