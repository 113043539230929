import React from 'react';
import Header from './components/Slider'
import Navbar from './components/Navbar'
import MultiItems from './components/MultiItems'
import AboutUs from './components/About';
import ContactUs from './components/Contact';
import Languages from "./language.json"

export const LanguageContext = React.createContext();

function App() {
  const [language, setLanguage] = React.useState(Languages.En);

  return (
    <LanguageContext.Provider value={language}>
        <Navbar setLanguage={setLanguage}/>
        <Header/>
        <MultiItems/>
        <AboutUs/>
        <ContactUs/>
    </LanguageContext.Provider>
  );
}

export default App;
