import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './Products.css'
import Button from '@mui/material/Button';
import Dialog from './Dialog'
import Fade from 'react-reveal/Fade';
import Product1 from '../img/products/potato-chips/bag1.png'
import Product2 from '../img/products/tortilla-chips/bag7.png'
import Product3 from '../img/products/poppable/bag10.png'
import Product4 from '../img/products/cones/bag13.png'
import Product5 from '../img/products/pillows/bag15.png'
import F1 from '../img/products/potato-chips/bag2.png'
import F2 from '../img/products/potato-chips/bag3.png'
import F3 from '../img/products/potato-chips/bag4.png'
import F4 from '../img/products/potato-chips/bag5.png'
import F5 from '../img/products/potato-chips/bag6.png'
import F7 from '../img/products/tortilla-chips/bag7.png'
import F8 from '../img/products/tortilla-chips/bag8.png'
import F9 from '../img/products/tortilla-chips/bag9.png'
import F10 from '../img/products/poppable/bag10.png'
import F11 from '../img/products/poppable/bag11.png'
import F12 from '../img/products/cones/bag12.png'
import F13 from '../img/products/cones/bag13.png'
import F14 from '../img/products/cones/bag14.png'
import F15 from '../img/products/pillows/bag16.png'
import F16 from '../img/products/pillows/bag15.png'
import F17 from '../img/products/pillows/bag17.png'
import F18 from '../img/products/ChocoP.png'
import F19 from '../img/products/puffs/puffOrg.jpg'
import F20 from '../img/products/puffs/puffCheese.jpg'
import S1 from '../img/sizes/size1.jpg'
import S2 from '../img/sizes/size2.jpg'
import S3 from '../img/sizes/size3.jpg'
import S4 from '../img/sizes/size4.jpg'
import S5 from '../img/sizes/size5.jpg'
import S6 from '../img/sizes/size6.jpg'
import S7 from '../img/sizes/size7.jpg'
import { LanguageContext } from '../App';

export default function Responsive() {
    const [open, setOpen] = React.useState(false);
    const [dialogItems, setDialogItems] = React.useState([]);
    const language = React.useContext(LanguageContext);
    const handleClickOpen = (items) => {

        setOpen(true);
        setDialogItems(items);

    };
    const handleClose = (value) => {
        setOpen(false);
    };

    var settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 5,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <div className="productsContainer" id="Products">
            <Fade bottom>
                <h1 className="title">{language.OURPRODUCTS} </h1>
            </Fade>
            <Fade bottom>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    items={dialogItems}
                />

                <Slider {...settings}>
                    <div>
                        <img
                            className=" productSlider"
                            src={Product1}
                            alt="First slide"
                        />
                      
                        <Button className="productBtn" onClick={() => handleClickOpen(potatoChips)}>
                        Potato Chips 
                        </Button>

                    </div>
                    <div>
                        <img
                            className=" productSlider"
                            src={Product2}
                            alt="First slide"
                        />
                    

                        <Button className="productBtn" onClick={() => handleClickOpen(tortillaChips)}>
                        Tortialla Chips
                        </Button>

                    </div>

                    <div>
                        <img
                            className=" productSlider"
                            src={Product3}
                            alt="First slide"
                        />
                        
                        <Button className="productBtn" onClick={() => handleClickOpen(poppable)}>
                          3D Corn Oval
                        </Button>
                    </div>

                    <div>
                        <img
                            className=" productSlider"
                            src={Product4}
                            alt="First slide"
                        />
                     
                        <Button className="productBtn" onClick={() => handleClickOpen(cones)}>
                        Cones
                        </Button>
                    </div>

                    <div>
                        <img
                            className=" productSlider"
                            src={Product5}
                            alt="First slide"
                        />
                      
                        <Button className="productBtn" onClick={() => handleClickOpen(pillows)}>
                        Chocolate Pillows
                        </Button>
                    </div>
                    <div>
                        <img
                            className=" productSlider"
                            src={F19}

                        />
                   
                        <Button className="productBtn" onClick={() => handleClickOpen(puffs)}>
                        Puffs
                        </Button>
                    </div>
                    <div>
                        <img
                            className=" productSlider"
                            src={F18}

                        />
                   
                        <Button className="productBtn" onClick={() => handleClickOpen(choco)}>
                        Choco Pillows
                        </Button>
                    </div>


                </Slider>
            </Fade>
        </div>
    );
}

const potatoChips = {
    size: { img: S1} ,
    flavour: [
        { image: Product1, flavour: "Mexican Chilli" },
        { image: F1, flavour: "Cheese" },
        { image: F2, flavour: "Cheese & Onion" },
        { image: F3, flavour: "Smoked Beef " },
        { image: F4, flavour: "Vinegar & Salt" },
        { image: F5, flavour: "Chutney" }
    ]
};

const tortillaChips = {
    size: { img: S2} ,
    flavour: [
    { image: F7, flavour: "Sweet Chilli Pepper", size: F7 },
    { image: F8, flavour: "Sour Cream & Mild Chilli" },
    { image: F9, flavour: "Cheese Supreme" }
    ]
};
const poppable = {
    size: { img: S3} ,
    flavour: [
    { image: F10, flavour: "Cheese", size: F10 },
    { image: F11, flavour: "Sweet Chilli" }
    ]
}
;
const cones = {
    size: { img: S5} ,
    flavour: [

    { image: F12, flavour: "Pizza", size: F12 },
    { image: F13, flavour: "Lime & Sweet Chilli" },
    { image: F14, flavour: "Cheese" }
    ]
}
;
const pillows = {
    size: { img: S6} ,
    flavour: [

    { image: F15, flavour: "Strawberry", size: F15 },
    { image: F16, flavour: "Chocolate" },
    { image: F17, flavour: "Mint Chocolate" }
    ]
}
;
const puffs = {
    size: { img: S4} ,
    flavour: [

    { image: F19, flavour: "Original Flavour", size: F19 },
    { image: F20, flavour: "Cheese" }
    ]
}
;
const choco = {
    size: { img:S7} ,
    flavour: [

    { image: F18, flavour: "Choco Pillows", size: F18 },
    ]
}
;