import React from 'react';
import './About.css'
import Img2 from '../img/potato-chips.jpg'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Fade from 'react-reveal/Fade';
import { LanguageContext } from '../App';

export default function About() {
    const language = React.useContext(LanguageContext);
    return (
        <div className='AboutContainer' id='About'>
    
            <Row xs={1} md={2} className='aboutRow'>

             <Fade right>
                <Col>
                    <h1 className="title">{language.ABOUTUS}</h1>
                    <p className='aboutText' >{language.AboutTxt}{language.AboutTxt1}</p>
                    <p className='aboutText' >{language.AboutTxt2}{language.AboutTxt3}</p>
                    <p className='aboutText' >{language.AboutTxt4}{language.AboutTxt5}</p>
                    
                </Col>
                </Fade>
                <Fade left>
                <Col className='imgCol'>
                 <img src={Img2} className='AboutImg' />
                </Col>
                </Fade>
            </Row>
        </div>
    );
}




